



































import { Component, Vue } from 'vue-property-decorator';
import UploadFile from '@c/upload/UploadFile.vue';
import FileEcho from '@c/upload/FileEcho.vue';
import { InstitutionReportAdd, InstitutionModuleGet } from '@/http/api/InstitutionApi';


@Component({
  components: {
    UploadFile,
    FileEcho,
  },
})
export default class DataReported extends Vue {
  private radioIndex: number = 1;
  private loading: boolean = false;
  private attFiles: any = '';
  private fileListClean: boolean = false;
  private attFileList1: any[] = [];
  private attFileList2: any[] = [];
  private attFileList3: any[] = [];
  private uploadFileConfig: any = {
    limit: 1,
    callback: [],
  };
  private created() {
    this.getModel();
  }
  // 获取模板
  private getModel(): void {
    InstitutionModuleGet({ module: 1 }).then((res) => {
      if (res.code === '000') {
        if (res.result && res.result.length > 0) {
          res.result.forEach((element: any) => {
            if (element.type === 1) {
              this.attFileList1.push(element.fileInfo);
            }
            if (element.type === 2) {
              this.attFileList2.push(element.fileInfo);
            }
            if (element.type === 3) {
              this.attFileList3.push(element.fileInfo);
            }
          });
        }
      }
    });
  }
  private articleSubmit(): void {
    if (!this.attFiles) {
      this.$message.warning('请上传工作计划');
      return;
    }
    this.loading = true;
    InstitutionReportAdd({
      file: this.attFiles,
      type: this.radioIndex,
    }).then((res) => {
      this.loading = false;
      if (res.code === '000') {
        this.$message.success('上传成功');
        this.radioIndex = 1;
        this.fileListClean = !this.fileListClean;
      }
    });
  }
  private UploadFileFun(val: any): void {
    this.attFiles = val;
  }
}
